.rumah1{
    font-weight: 200;
    background-color:#1f565c ;
    font-style: normal;
    font-family: sans-serif;
    //margin-top: -125px;
    padding-top: 5px;
    margin-bottom: 25px;
    padding-bottom: 50px;
    @media (max-width: 768px){
        margin-top: 20px;
    }
    .judulnewlaunch {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 50px;
        margin-top: 100px;

        @media (max-width: 768px) {
          margin-top: 10px;
        }
        .judul {
          width: 100%;
          font-weight: 600;
          font-size: 40px;
          color: white;
          @media (max-width: 768px) {
            text-align: center;
          }
          h1 {
            width: 100%;
            text-align: center;
            font-family: "poppins" sans-serif;
            font-size: 50px;
            font-weight:400;
            margin-top: -5%;
            color: #FBBF68;
            @media (max-width: 768px) {
              width: 100%;
              text-align: center;
              font-size: 50px;
              margin-top: 5%;
            }
          }
          h2 {
            width: 100%;
            text-align: center;
            font-family: "poppins" sans-serif;
            font-size: 30px;
            font-weight: 500;
            margin-top: -1%;
            @media (max-width: 768px) {
              width: 100%;
              text-align: center;
              font-size: 30px;
              margin-top: -15%;
            }
          }

          
        }
      }
      
      .containerPenawaran {
        margin-top: 30px;
        height: 100%;
        width: 100%;
        display: flex;
        margin-bottom: 50px;
        justify-content: flex-end; /* Added justify-content */
        @media (max-width: 768px) {
          flex-direction: column;
          margin-top: 0;
        }
        .containergambar {
          order: 1;
          width: 50%;
          text-align: right;
          margin-top: -20px;
          @media (max-width: 768px) {
            margin-top: 25px;
            order: 1;
            width: 100%;
            text-align: center;
          }
      
          .penawarangambar {
            width: 500px;
            height: 650px;
            align-self: flex-end;
            box-shadow: 0 0 10px 0;
            border-radius: 5px;
            @media (max-width: 768px) {
              width: 90%;
              height: 100%;
            }
          }
        }
      
        .containercontent {
          order: 2;
          width: 50%;
          margin-left: 100px;
          text-align: left;
          justify-content: center;
          display: flex;
          flex-direction: column;
          @media (max-width: 768px) {
            margin-left: 25px;
            width: 90%;
            order: 2;
            margin-top: 15px;
          }
        }
        .disclaimer {
          margin-top: 25px;
          font-size: 13px;
          color: white;
        }
        .pointpenawaran {
          flex-direction: row;
          font-size: 30px;
          @media (max-width: 768px) {
            font-size: 25px;
            width: 100%;
          }
          .penawaranpoin {
            margin-top: 0px;
            color: #FBBF68;
            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }
        .buttonpenawaran {
          color: #1f565c;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          margin-top: 30px;
          margin-left: 15%;
          background-color: white;
          width: 50%;
          padding: 15px 30px;
          border: 1px solid white; /* Add this line for black border */
          border-color: white;
          border-radius: 25px;
          font-size: 16px;
          font-weight: light;
          cursor: pointer;
          display: inline-block;
          transition: background-color 0.3s ease, color 0.3s ease;
          @media (max-width: 768px) {
            justify-content: center;
            align-items: center;
            margin-left: 15%;
            width: 70%;
          }
          &:hover {
            background-color: transparent;
            color: white;
            border-color: white;
          }
          
        }
        .slider1{
            width: 100%;
        }
      }
    .container-header1{
        justify-content: center;
        margin-top: 4%;
        padding-bottom: 10%;
        font-size: 60px;
        font-family: 'Poppins' sans-serif;
        font-weight: 400;
        text-align: center;
        color: #C88C39;
        margin-bottom: 20px;
        @media (max-width: 768px){
            margin-top: -15%;
            font-size: 36px;
        }
    }
    .Judulnewlaunch{
        display: flex ;
        justify-content: center;
        font-family: 'poppins' ;
          font-size: 50px;
          font-weight: 500;
          color: rgb(255, 253, 253);
          margin-top: 50px;
          margin-bottom: 50px;
          @media (max-width: 768px){
            text-align: center;
            font-size: 35px;
            font-weight: 500;
            font-family: 'poppins' sans-serif;
            margin-bottom: 75px;
        }

    }
    h1{
        width: 100%;
        color: white;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        font-family: 'poppins' sans-serif;
        font-weight: 500;
        font-size: 50px;
        margin-top: -30px;
        @media (max-width: 768px){
            text-align: center;
            font-size: 35px;
            font-weight: 500;
            font-family: 'poppins' sans-serif;
            margin-bottom: 75px;
            margin-top: -15%;
        }
    }
    .rumah-card{
        @media (max-width: 768px){
            margin-top: -15%;}
    }
}
