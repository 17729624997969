.about-sumban {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .containerlogo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
    }
    .logosumban {
      width: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      @media (max-width: 768px) {
        width: 100%;
      }
      .gambarsumban {
        width: 50%;
        @media (max-width: 768px) {
          width: 50%;
        }
      }
    }
  }
  .containerdesk {
    width: 100%;
    @media (max-width: 768px) {
      width: 80%;
      margin-top: -20%;
      padding: 10px 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .judul1 {
      width: 100%;
      @media (max-width: 768px) {
        width: 100%;
        padding: 10px 50px;
        display: flex;
      flex-direction: column;
      justify-content: center;
      }

      h1 {
      display: flex;
      justify-content: center;
      justify-items: center;
      align-items: center;
      text-align: center;
      margin-left: -22%;
      margin-top: 50px;
      font-family: "poppins" sans-serif;
      font-size: 35px;
      font-weight: 600;
      color: #1f565c;
      }
      @media (max-width: 768px) {
        width: 90%;
        padding: 10px 20px;
        display: flex;
      flex-direction: column;
      justify-content: center;
      }
    }
    .desk {
      width: 100%;
      display: flex;
      justify-content: center;
      justify-items: center;
      align-items: center;
      text-align: center;
      margin-left: -10%;
      color: rgb(85, 85, 85);
      font-family: "poppins" sans-serif;
      font-size: 15px;
    }
  }
}