.lokasi {
  width: 100%;
  margin-top: 20px;
  @media (max-width: 768px) {
   margin-top: 10%;
  }
    .judul {
      font-size: 65px;
      font-family: "Poppins", sans-serif;
      color: #f4b558;
      font-weight: 400;
      text-align: center;
      @media (max-width: 768px) {

      }
    }
    .maps-lokasi {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column;
        overflow: hidden;

      }
      .img-lokasi-dekstop {
        width: 75%;
        height: 500px;
        border-radius: 10px;
        @media (max-width: 768px) {
          visibility: hidden;
          width: 0%;
          height: 0%;
        }
      }
      .img-lokasi-mobile {
        visibility: hidden;
        width: 0%;
        @media (max-width: 768px) {
          visibility: visible;
          width: 90%;
          height: 75%;
          margin-top: -20%;
        }
      }
    }
    .benefit-container {
      display: flex;
      flex-direction: row;
      margin-top: 25px;
      justify-content: center;
      align-items: center;
      align-content: center;
      @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .benefit-point {
        color: #535353;
        padding-right: 45px;
        @media (max-width: 768px) {
          flex-direction: column;
          padding-right: 0;
          padding-left: 25px;
          width: 80%;
          
        }
        .benefit-title {
          font-family: "Robbotto", Sans-serif;
          font-size: 40px;
          font-weight: 600;
          font-size: 30px;
          padding: 10px 25px 25px 0;
        }
        .benefit {
          font-size: 18px;
        }
      }
    }
  }