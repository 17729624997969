@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.surroundingarea {
    background-color: #1f565c;
    padding-bottom: 20px;
    margin-top: -100px;
    @media (max-width: 768px) {
        
      }
      
    .container-surrounding {
        width: 100%;
        background-color: #1f565c;
        .judul-surrounding {
            width: 100%;
            display: flex;
            align-content: center;
            justify-content: center;
            background-color: #1f565c;
            @media (max-width: 768px) {
                background-color: #1f565c;
              }

            //margin-bottom: 2%;
            h1 {
                font-family: 'poppins' sans-serif;
                font-size: 50px;
                font-weight: 400;
                color: #FBBF68;
                @media (max-width: 768px) {
                    text-align: center;
                    font-size: 32px;
                }
            }
        }

        .container-card {
            .kartu {
                .kartugambar1 {
                    width: 100%;
                    margin: auto;
                    border-radius: 5px;

                    @media (max-width: 768px) {
                        width: 95%;
                        height: 75%;
                    }


                }

                .slick-next::before,
                .slick-prev::before {
                    font-family: "slick";
                    font-size: 30px;
                }

                .slick-next {
                    right: 100px;

                    @media (max-width: 768px) {
                        right: 50px;
                    }
                }

                .slick-prev {
                    z-index: 1;
                    left: 100px;

                    @media (max-width: 768px) {
                        left: 50px;
                    }
                }

                .slick-dots {
                    position: absolute;
                    /* Make dots absolute for positioning within slides */
                    bottom: 10px;
                    /* Adjust vertical positioning */
                    left: 50%;
                    /* Center dots horizontally */
                    transform: translateX(-50%);
                    /* Center dots precisely */
                    z-index: 1;

                    /* Ensure dots are above image content */
                    li {
                        margin: 0;
                    }
                }
            }
        }
    }
}